<template>
    <v-dialog
        transition="dialog-bottom-transition"
        max-width="600"
        v-model="login.showForgotPass"
    >
        <v-card>
            <v-toolbar dark :color="parameters.secondaryColor" class="title">Esqueceu sua senha?</v-toolbar>
            <v-card-subtitle class="pt-5">Para recuperar sua senha, é necessário informar o seu {{ parameters.store.b2bcpf ? "CPF ou CNPJ" : "CNPJ"}} e você receberá no seu e-mail do cadastro as instruções para retomar o acesso.</v-card-subtitle>
            <v-card-text>
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-text-field
                        v-model="cpfcnpj"
                        :rules="cpfcnpjRules"
                        :label="parameters.store.b2bcpf ? 'CPF/CNPJ' : 'CNPJ'"
                        required
                        dense
                        flat
                        solo
                        outlined
                        prepend-icon="mdi-account"
                        :color="parameters.primaryColor"
                        v-mask="maskCustomer"
                    ></v-text-field>
                </v-form>
            </v-card-text>
            <v-card-actions class="justify-end">
                <v-btn text @click="login.showForgotPass = false">Fechar</v-btn>
                <v-btn dark :color="parameters.secondaryColor" :loading="login.loadingForgotPass" @click="send">Prosseguir</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data: () => ({
        cpfcnpj: "",
        valid: true
    }),
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
        login: {
            get() {
                return this.$store.state.login;
            },
        },
        maskCustomer(){
            if (!this.parameters.store.b2bcpf || (this.cpfcnpj && this.cpfcnpj.replace(/[^\d]/g, "").length > 11))
                return "##.###.###/####-##"
            return "###.###.###-##"
        },
        cpfcnpjRules(){
            return [
                (v) => !!v || (this.parameters.store.b2bcpf ? "CPF/CNPJ é obrigatório" : "CNPJ é obrigatório"),
                (v) => (v && v.replace(/[^\d]/g, "").length != 14) ? (this.parameters.store.b2bcpf ? ((v && v.replace(/[^\d]/g, "").length != 11) ? "CPF inválido" : true ): "CNPJ inválido") : true,
            ]
        }
    },
    methods:{
        send(){
            if (this.$refs.form.validate()) {
                this.login.loadingForgotPass = true
                this.$store.dispatch("login/SendRecoverPass", this.cpfcnpj)
                .then(payload => {
                    this.cpfcnpj = ""
                })
            }
        }
    }
};
</script>

<style>
</style>