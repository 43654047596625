<template>
    <v-main style="min-height: 80% !important;" >
        <v-container>
            <v-row class="pt-5">
                <v-col cols="12">
                    <h2 :style="{color: parameters.primaryColor}">Bem vindo(a)!</h2>
                </v-col>
                <v-col cols="12" v-if="login.isError" >
                    <FormAlert :messageError="login.messageError"/>
                </v-col>
                <v-col :cols="$mq != 'lg' ? 12 : 6">
                    <LoginSignInCard />
                </v-col>
                <v-col :cols="$mq != 'lg' ? 12 : 6">
                    <LoginSignUpCard />
                </v-col>
            </v-row>
        </v-container>
    </v-main>
</template>

<script>
const LoginSignInCard = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/login/LoginSignInCard.vue"
    );
const LoginSignUpCard = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/login/LoginSignUpCard.vue"
    );
const FormAlert = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/form/FormAlert.vue"
    );

export default {
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
        login: {
            get() {
                return this.$store.state.login;
            },
        },
    },
    components:{
        LoginSignInCard,
        LoginSignUpCard,
        FormAlert,
    }
};
</script>

<style>
</style>