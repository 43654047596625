<template>
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
      <p class="text--center white-text">Sua sessão expirou. <br/>Você está sendo redirecionado.</p>
    </v-overlay>
</template>

<script>
export default {
    data(){
        return{
            overlay: true
        }
    },
    created(){
        this.$store.dispatch("login/logoff")
    }
}
</script>

<style>

</style>