<template>
    <p class="ma-0">
        <v-icon slot="icon" color="error" size="19">
            mdi-cancel
        </v-icon>
        {{ messageError }}
    </p>
</template>

<script>
export default {
    props:{
        messageError:{
            type: String,
            required: true
        }
    }
};
</script>

<style>
</style>