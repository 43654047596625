<template>
    <v-card elevation="1" outlined min-height="302">
        <v-card-title>Quero criar uma conta</v-card-title>
        <v-card-subtitle
            >Digite abaixo o {{ parameters.store.b2bcpf ? "CPF/CNPJ" : "CNPJ"}} da sua empresa que deseja cadastrar:</v-card-subtitle
        >
        <v-card-text>
            <v-form ref="form" lazy-validation>
                <v-text-field
                    v-model="signUp.account.cf1cliente"
                    :label="parameters.store.b2bcpf ? 'CPF/CNPJ' : 'CNPJ'"
                    dense
                    flat
                    solo
                    placeholder="Ex.: 01.234.567/8901-23"
                    outlined
                    prepend-icon="mdi-account"
                    :color="parameters.primaryColor"
                    v-mask="maskCustomer"
                    @keydown="keydown"
                ></v-text-field>
            </v-form>
        </v-card-text>
        <v-card-actions class="d-flex justify-center align-center">
            <v-btn
                :color="parameters.secondaryColor"
                class="white--text"
                width="80%"
                @click="clickSignUp"
            >
                Me cadastrar
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    data: () => ({
    }),
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
        signUp: {
            get() {
                return this.$store.state.signUp;
            },
        },
        maskCustomer(){
            if (!this.parameters.store.b2bcpf || (this.signUp.account.cf1cliente && this.signUp.account.cf1cliente.replace(/[^\d]/g, "").length > 11))
                return "##.###.###/####-##"
            return "###.###.###-##"
        },
    },
    methods: {
        clickSignUp(){
            this.$router.push("/cadastro")
        },
        keydown(event){
            if (event.keyCode === 13 && !event.shiftKey) {
                this.clickSignUp()
                event.preventDefault();
            }
        },
    },
};
</script>

<style>
</style>